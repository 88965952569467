<template>
<span>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 1315.6 2048" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
  <g transform="translate(-.10384 -14.188)">
    <path d="m107.07 723.65c-209.32 356.79-55.234 681.32 146.06 914.02-103.6-187.6-179.72-450.09-156.52-810.23 2.3167-35.96 7.2258-69.086 12.974-100.86-0.8638-0.98165-1.647-1.9368-2.511-2.9296zm1102.4 20.088c1.4812 5.1793 2.6088 10.273 4.185 15.485 49.208 252.08 21.008 622.06-143.97 916.53 257.82-332.39 311.34-751.96 139.78-932.02z" :fill="fill2" :stroke="stroke1" stroke-width="14.116px"/>
    <g transform="matrix(.99338 0 0 .99338 .00068782 13.66)">
    <path d="m58.502 20.272s603.77 549.12 1184.5 2.0534c-62.676 262.71-97.865 475.83-21.519 728.28 76.125 389.97-30.946 1060.5-540.66 1304.2 0 0-645.75-268.35-583.43-1235.7 16.421-254.89 149.6-373.96-38.895-798.85z" :fill="fill1" :stroke="stroke1"/>
    <path d="m1254.8 1.9375-16.438 15.469c-288.7 271.96-581.47 271-803.41 202.59s-371.94-204.72-371.94-204.72l-10.688 7.7188c93.912 211.69 107.39 346.08 95.719 459.75s-49.125 207.39-57.406 335.94c-31.258 485.19 115.23 796.09 269.84 985.28s317.75 257.06 317.75 257.06l2.7812 1.1563 2.7188-1.3125c256.87-122.78 412.13-353.11 493.5-601.19s89.191-514.09 50.875-710.38l-0.062-0.34375-0.125-0.3125c-75.875-250.89-40.992-462.28 21.625-724.75l5.25-21.969zm-23.344 40.031c-59.5 253.21-91.127 463.3-16.531 710.25 37.741 193.66 30.018 457.78-50.5 703.25-80.264 244.7-232.59 470.65-483.78 591.84-14.269-6.1941-164.36-74.047-309.69-251.88-152.68-186.82-297.9-493.75-266.84-975.88 8.1394-126.34 45.48-219.62 57.375-335.44 11.4-110.99-1.3356-242.18-85.656-440.56 48.71 39.337 178.87 135.01 355.16 189.34 220.98 68.109 513.57 69.208 800.47-190.94z" color="#000000" :fill="fill2" :stroke="stroke1" stroke-width="8px"/>
    </g>
    <path d="m1140.8 793.96c-60.6 76.622-157.7 125.97-267.43 125.97-79.63 0-152.8-25.807-210.09-69.472-57.247 43.527-130.6 69.472-210.09 69.472-101.55 0-192.18-42.153-253.2-109.23 20.314 659.33 475.43 847.48 475.43 847.48 306.73-126.56 459.45-583.65 465.38-864.22z" :fill="fill1" :stroke="stroke1" stroke-width="14px"/>
    <g transform="matrix(13.392 0 0 13.392 -3012.2 -1612.9)">
    <path d="m258.77 142.61c-13.73 0-24.846 10.409-24.846 23.266s11.116 23.266 24.846 23.266c5.9354 0 11.411-1.9496 15.686-5.1998 4.278 3.2605 9.7401 5.1998 15.686 5.1998 13.73 0 24.846-10.409 24.846-23.266s-11.116-23.266-24.846-23.266c-5.9459 0-11.408 1.9392-15.686 5.1998-4.2746-3.2501-9.7506-5.1998-15.686-5.1998z" color="#000000" :fill="fill2" :stroke="stroke1" fill-rule="evenodd"/>
    <path d="m260.4 143.76c-12.306 0-22.269 9.963-22.269 22.269s9.963 22.269 22.269 22.269c5.3197 0 10.228-1.8661 14.059-4.9769 3.8342 3.1208 8.7296 4.9769 14.059 4.9769 12.306 0 22.269-9.963 22.269-22.269s-9.963-22.269-22.269-22.269c-5.3291 0-10.225 1.8561-14.059 4.9769-3.8312-3.1108-8.7391-4.9769-14.059-4.9769z" color="#000000" :fill="fill1" fill-rule="evenodd"/>
    <g>
      <path transform="matrix(1.2041 0 0 1.2041 -58.689 -38.779)" d="m274.52 170.44c0 6.0058-4.8687 10.874-10.874 10.874-6.0058 0-10.874-4.8687-10.874-10.874 0-6.0058 4.8687-10.874 10.874-10.874 6.0058 0 10.874 4.8687 10.874 10.874z" color="#000000" :fill="fill2" :stroke="stroke1" fill-rule="evenodd"/>
      <path transform="matrix(.7551 0 0 .7551 59.685 37.746)" d="m274.52 170.44c0 6.0058-4.8687 10.874-10.874 10.874-6.0058 0-10.874-4.8687-10.874-10.874 0-6.0058 4.8687-10.874 10.874-10.874 6.0058 0 10.874 4.8687 10.874 10.874z" color="#000000" fill-rule="evenodd"/>
      <path transform="matrix(.31633 0 0 .31633 178.68 109.35)" d="m274.52 170.44c0 6.0058-4.8687 10.874-10.874 10.874-6.0058 0-10.874-4.8687-10.874-10.874 0-6.0058 4.8687-10.874 10.874-10.874 6.0058 0 10.874 4.8687 10.874 10.874z" color="#000000" fill="#fff" fill-rule="evenodd"/>
    </g>
    <g transform="translate(32.17)" width="371" height="419">
      <path transform="matrix(1.2041 0 0 1.2041 -58.689 -38.779)" d="m274.52 170.44c0 6.0058-4.8687 10.874-10.874 10.874-6.0058 0-10.874-4.8687-10.874-10.874 0-6.0058 4.8687-10.874 10.874-10.874 6.0058 0 10.874 4.8687 10.874 10.874z" color="#000000" :fill="fill2" :stroke="stroke1" fill-rule="evenodd"/>
      <path transform="matrix(.7551 0 0 .7551 59.685 37.746)" d="m274.52 170.44c0 6.0058-4.8687 10.874-10.874 10.874-6.0058 0-10.874-4.8687-10.874-10.874 0-6.0058 4.8687-10.874 10.874-10.874 6.0058 0 10.874 4.8687 10.874 10.874z" color="#000000" fill-rule="evenodd"/>
      <path transform="matrix(.31633 0 0 .31633 178.68 109.35)" d="m274.52 170.44c0 6.0058-4.8687 10.874-10.874 10.874-6.0058 0-10.874-4.8687-10.874-10.874 0-6.0058 4.8687-10.874 10.874-10.874 6.0058 0 10.874 4.8687 10.874 10.874z" color="#000000" fill="#fff" fill-rule="evenodd"/>
    </g>
    </g>
    <g transform="matrix(.99338 0 0 .99338 .00068782 13.66)">
    <path d="m58.502 20.272s603.77 549.12 1184.5 2.0534c-36.554 214-511.85 361.19-572.25 519.55-64.805-165.97-557.05-292.77-612.26-521.6z" :fill="fill2" :stroke="stroke1"/>
    <path d="m46.812 0.53124 5.125 21.313c28.609 118.58 167.45 207.47 305.69 288.62 69.121 40.578 138.36 79.111 194.06 117.63 55.706 38.514 97.526 77.149 112.78 116.22l6.375 16.281 6.2188-16.312c14.176-37.163 54.197-75.745 107.44-114.88 53.241-39.13 119.38-79.021 184.94-120.75 65.562-41.729 130.57-85.272 181.78-132.16 51.207-46.884 88.912-97.268 98.437-153.03l3.375-19.844-14.656 13.781c-288.7 271.96-581.46 271-803.41 202.59-221.94-68.405-371.94-204.72-371.94-204.72l-16.219-14.75zm24.875 39.625c44.672 36.645 177.25 136.61 359.31 192.72 220.92 68.089 513.4 69.236 800.22-190.69-13.682 43.488-45.909 84.804-89.094 124.34-50.078 45.851-114.53 89.137-179.91 130.75s-131.68 81.588-185.69 121.28c-48.086 35.342-86.648 70.291-105.84 107.19-20.252-38.501-60.739-73.763-111.34-108.75-56.542-39.092-125.96-77.683-194.91-118.16-128.82-75.627-255.03-158.24-292.75-258.69z" color="#000000" :fill="fill2" :stroke="stroke1" stroke-width="14px"/>
    </g>
    <path d="m598.98 859.34c-0.32946 33.944 42.352 132.17 66.782 147.41 28.518-16.813 64.645-113.08 64.645-147.41s-39.945-67.777-64.645-67.777c-32.712 0-66.453 33.833-66.782 67.777z" :fill="fill2" :stroke="stroke1" stroke-width="8px"/>
    <g transform="matrix(13.392 0 0 13.392 -3108 -1697.2)" :fill="fill1" fill-rule="evenodd" :stroke="stroke1" stroke-width=".565">
    <path d="m259.64 261.3c1.6118 0 3.3721 1.3654 2.9094 2.9094-1.119 3.734-0.2391 6.2686 0 9.1877 0.13158 1.6064-1.2976 2.9094-2.9094 2.9094s-2.6876-1.313-2.9094-2.9094c-0.75204-5.4127-0.40935-7.4044 0-9.1877 0.36061-1.571 1.2976-2.9094 2.9094-2.9094z" color="#000000"/>
    <path d="m270.72 261.24c1.6118 0 2.5503 1.3381 2.9094 2.9094 0.97116 4.2495 0.27527 6.4616 0 9.1877-0.16193 1.6037-1.2976 2.9094-2.9094 2.9094s-3.2044-1.3248-2.9094-2.9094c0.55411-2.9773 1.3023-5.9248 0-9.1877-0.5975-1.497 1.2976-2.9094 2.9094-2.9094z" color="#000000"/>
    <path d="m265.22 261.3c1.6118 0 2.5619 1.3355 2.9094 2.9094 0.92625 4.1946 0.3468 6.549 0 9.1877-0.21003 1.5981-1.2976 2.9094-2.9094 2.9094s-2.5543-1.3372-2.9094-2.9094c-0.72147-3.1938-0.90178-6.2891 0-9.1877 0.47882-1.5391 1.2976-2.9094 2.9094-2.9094z" color="#000000"/>
    </g>
    <g transform="matrix(13.392 0 0 13.392 -2653.5 -1693)" :fill="fill1" fill-rule="evenodd" :stroke="stroke1" stroke-width=".565">
    <path d="m259.64 261.3c1.6118 0 3.3721 1.3654 2.9094 2.9094-1.119 3.734-0.2391 6.2686 0 9.1877 0.13158 1.6064-1.2976 2.9094-2.9094 2.9094s-2.6876-1.313-2.9094-2.9094c-0.75204-5.4127-0.40935-7.4044 0-9.1877 0.36061-1.571 1.2976-2.9094 2.9094-2.9094z" color="#000000"/>
    <path d="m270.72 261.24c1.6118 0 2.5503 1.3381 2.9094 2.9094 0.97116 4.2495 0.27527 6.4616 0 9.1877-0.16193 1.6037-1.2976 2.9094-2.9094 2.9094s-3.2044-1.3248-2.9094-2.9094c0.55411-2.9773 1.3023-5.9248 0-9.1877-0.5975-1.497 1.2976-2.9094 2.9094-2.9094z" color="#000000"/>
    <path d="m265.22 261.3c1.6118 0 2.5619 1.3355 2.9094 2.9094 0.92625 4.1946 0.3468 6.549 0 9.1877-0.21003 1.5981-1.2976 2.9094-2.9094 2.9094s-2.5543-1.3372-2.9094-2.9094c-0.72147-3.1938-0.90178-6.2891 0-9.1877 0.47882-1.5391 1.2976-2.9094 2.9094-2.9094z" color="#000000"/>
    </g>
  </g>
  </svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'owl',

  mixins: [ThemeMixin]
}
</script>
